import { Alert, Container, Spinner } from 'react-bootstrap'
import OtpModal from './Otp/OtpModal'
import { UserForm } from './User'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../Context/UserContext'
import { AuthenticationContext } from '../Context/AuthenticationContext'
import { ConfigError, User, getUser } from './Otp/api'
import { StatusCodes } from 'http-status-codes'
import Logout from './Logout'
import { AxiosError, AxiosResponse } from 'axios'
import { ComponentAlert } from './Otp/type'

export default function MainPage(): JSX.Element {
  const [isReady, setIsReady] = useState(false)
  const { user, setUser } = useContext(UserContext)
  const [alert, setAlert] = useState<ComponentAlert>()
  const { isAuthenticated, setIsAuthenticated } = useContext(
    AuthenticationContext,
  )

  const setupAlert = (
    value: React.SetStateAction<ComponentAlert | undefined>,
  ): void => {
    setAlert(value)

    setTimeout(() => setAlert(undefined), 5000)
  }

  const handleLoginRespponse = (result: AxiosResponse<User>): void => {
    if (result.status !== StatusCodes.OK) {
      setupAlert({
        variant: 'danger',
        message: `Login failed: ${JSON.stringify(result.data)}`,
      })

      return
    }

    setUser(result.data)
    setIsAuthenticated(true)
    setupAlert({
      variant: 'success',
      message: 'You are logged in',
    })
  }

  const handleLoginError = (err: unknown): void => {
    setIsAuthenticated(false)

    if (err instanceof ConfigError) {
      return
    }

    if (err instanceof AxiosError) {
      setupAlert({
        variant: 'danger',
        message: err.message,
      })
    }
  }

  useEffect(() => {
    setIsReady(false)

    getUser()
      .then(handleLoginRespponse)
      .catch(handleLoginError)
      .finally(() => {
        setIsReady(true)
      })
  }, [isAuthenticated])

  const renderOtpModal = (): JSX.Element =>
    isAuthenticated ? <></> : <OtpModal />
  const renderUserForm = (): JSX.Element =>
    isAuthenticated ? <UserForm /> : <></>
  const renderLogout = (): JSX.Element => (isAuthenticated ? <Logout /> : <></>)
  const renderAlert = (): JSX.Element =>
    alert ? <Alert variant={alert.variant}>{alert.message}</Alert> : <></>

  const renderComponents = (): JSX.Element =>
    isReady ? (
      <>
        <center>
          {renderLogout()}
          {renderOtpModal()}
        </center>
        {renderUserForm()}
      </>
    ) : (
      <center>
        <Spinner animation="border" />
      </center>
    )

  return (
    <Container className="p-3">
      <Container className="p-5 mb-4 bg-light rounded-3">
        <center>
          <h1 className="header"> Welcome {user?.name}</h1>
        </center>
      </Container>
      {renderAlert()}
      {renderComponents()}
    </Container>
  )
}
