import React, {
  ChangeEventHandler,
  FormEventHandler,
  useContext,
  useState,
} from 'react'
import { Button, Form, Alert, InputGroup } from 'react-bootstrap'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import Cookie from 'js-cookie'

import '../../firebase-config'
import { auth } from '../../firebase-config'
import { AuthenticationContext } from '../../Context/AuthenticationContext'
import { ComponentAlert, CustomWindow } from './type'

const phoneNumberRegex = /^\+\d+$/

const OTP_LENGHT = 6

declare const window: CustomWindow

export const OtpForm: React.FC = () => {
  const { setIsAuthenticated } = useContext(AuthenticationContext)
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [isSendSmsBtnDisabled, setIsSendSmsBtnDisabled] = useState(true)
  const [formError, setFormError] = useState(false)
  const [alert, setAlert] = useState<ComponentAlert>()
  const [otpInputDisabled, setOtpInputDisabled] = useState(false)
  const [renderOtpInput, setRenderOtpInput] = useState(false)

  const verifyOTP = (code: string): void => {
    window
      .confirmationResult!.confirm(code)
      .then(async (result) => {
        const token = await result.user.getIdTokenResult()
        Cookie.set('token', token.token, {
          expires: new Date(token.expirationTime),
        })
        setIsAuthenticated(true)
      })
      .catch((e: Error) => {
        console.error(e.message)
        setAlert({
          variant: 'warning',
          message: 'OTP failed',
        })
        setFormError(true)
      })
  }

  const ferifyPhone = (): void => {
    try {
      setAlert({
        variant: 'success',
        message: 'SMS with code sent',
      })
      const verifier = new RecaptchaVerifier(auth, 'sign-in-button', {
        size: 'invisible',
      })

      signInWithPhoneNumber(auth, phoneNumber, verifier)
        .then((confirmation) => {
          window.confirmationResult = confirmation
          setRenderOtpInput(true)
        })
        .catch((err: Error) => {
          console.log('signInWithPhoneNumber err', { err, phoneNumber })
          setAlert({
            variant: 'danger',
            message: err.message,
          })
          setFormError(true)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const isPhoneNumberValid = (input: string): boolean =>
    phoneNumberRegex.test(input)

  const onClickHandler: FormEventHandler<HTMLButtonElement> = () => {
    if (!isPhoneNumberValid(phoneNumber)) {
      return setAlert({
        variant: 'danger',
        message:
          'Invalid phone number. Only County code and numbers are allowed eg: +48503603000',
      })
    }

    setIsSendSmsBtnDisabled(true)
    ferifyPhone()
  }

  const safePhoneNymber = (value: string): string => value.replace(/\s/g, '')

  const inputPhoneNumberHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setAlert(undefined)
    setRenderOtpInput(false)
    setPhoneNumber(safePhoneNymber(e.target.value))
    setIsSendSmsBtnDisabled(false)
  }

  const inputOtpHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target

    if (value.length === OTP_LENGHT) {
      setOtpInputDisabled(true)
      verifyOTP(value)
    }
  }

  const renderAlert = (): JSX.Element => {
    if (!alert) return <></>

    return <Alert variant={alert.variant}>{alert.message}</Alert>
  }

  const renderPhoneNumberInput = (): JSX.Element => (
    <>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Enter phone number with country prefix eg: +48503603032"
          onChange={inputPhoneNumberHandler}
          type="text"
          value={phoneNumber}
          id="inputPhoneNumber"
          aria-describedby="phoneNumberHelpBlock"
        />
        <Button
          variant="primary"
          disabled={isSendSmsBtnDisabled}
          onClick={onClickHandler}
          className="mr-1"
        >
          Send SMS
        </Button>
      </InputGroup>
      <Form.Label htmlFor="inputPhoneNumber"></Form.Label>
    </>
  )

  const otpInput = (): JSX.Element => {
    if (!renderOtpInput) return <></>

    return (
      <>
        <Form.Label htmlFor="inputOtp">SMS code</Form.Label>
        <Form.Control
          onChange={inputOtpHandler}
          disabled={otpInputDisabled}
          type="text"
          id="inputOtp"
          aria-describedby="otpHelpBlock"
        />
      </>
    )
  }

  const handleTryAgainBtn = (): void => {
    setFormError(false)
    setAlert(undefined)
    setPhoneNumber('')
    setRenderOtpInput(false)
    setOtpInputDisabled(false)
  }

  const renderForm = (): JSX.Element =>
    formError ? (
      <center>
        <Button onClick={handleTryAgainBtn}>Try again</Button>
      </center>
    ) : (
      <>
        {renderPhoneNumberInput()}
        {otpInput()}
        <div id="sign-in-button"></div>
      </>
    )

  return (
    <div className="p-1">
      {renderAlert()}
      {renderForm()}
    </div>
  )
}
