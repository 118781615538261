import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyA2IZ8zPzAwAsZF_dSeCrh6NfslLzAXvJI',
  authDomain: 'natural-cycles-challenge-ca00c.firebaseapp.com',
  projectId: 'natural-cycles-challenge-ca00c',
  storageBucket: 'natural-cycles-challenge-ca00c.appspot.com',
  messagingSenderId: '122603693927',
  appId: '1:122603693927:web:8faa8106e068d02e58f1c2',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
