import { ChangeEventHandler, useContext, useState } from 'react'
import { Alert, Button, Form, Spinner } from 'react-bootstrap'
import { UserContext } from '../../Context/UserContext'
import { ApiErrorResponse, updateUser } from '../Otp/api'
import { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'
import { updateUserSchema } from './validationSchema'
import { ComponentAlert } from '../Otp/type'

export const UserForm: React.FC = () => {
  const { user, setUser } = useContext(UserContext)

  const [inProgress, setInProgress] = useState(false)
  const [name, setName] = useState(user.name)
  const [email, setEmail] = useState(user.email)
  const [alert, setAlert] = useState<ComponentAlert>()

  const resetAlert = (): void => setAlert(undefined)

  const onClickHandler = (): void => {
    setInProgress(true)
    resetAlert()

    const updatedUser = {
      email,
      name,
    }

    const { error } = updateUserSchema.validate(updatedUser)

    if (error) {
      setAlert({
        variant: 'warning',
        message: error.message,
      })
      setInProgress(false)

      return
    }

    updateUser(updatedUser)
      .then((resp) => {
        if (resp.status === StatusCodes.CREATED) {
          setUser(resp.data)

          setAlert({
            variant: 'success',
            message: 'Updated',
          })
        }
      })
      .catch((err: AxiosError) => {
        console.log(err)
        setAlert({
          variant: 'warning',
          message: (err.response?.data as ApiErrorResponse).message,
        })
      })
      .finally(() => setInProgress(false))
  }

  const renderAlert = (): JSX.Element => {
    if (!alert) return <></>

    return <Alert variant={alert.variant}>{alert.message}</Alert>
  }

  const renderSpinner = (): JSX.Element => {
    return inProgress ? (
      <center>
        <Spinner animation="border" />
      </center>
    ) : (
      <></>
    )
  }

  const changeNameHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    resetAlert()
    setName(e.target.value)
  }

  const changeEmailHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    resetAlert()
    setEmail(e.target.value)
  }

  return (
    <Form>
      <center>
        <h1>Profile page</h1>
      </center>
      {renderAlert()}
      {renderSpinner()}
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          disabled={inProgress}
          type="email"
          onChange={changeEmailHandler}
          placeholder="name@example.com"
          value={email}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Name</Form.Label>
        <Form.Control
          disabled={inProgress}
          type="text"
          value={name}
          onChange={changeNameHandler}
        />
      </Form.Group>
      <Button
        disabled={inProgress}
        className="pull-right"
        onClick={onClickHandler}
      >
        Save
      </Button>
    </Form>
  )
}
