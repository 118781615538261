import Cookies from 'js-cookie'
import { useContext, FormEventHandler } from 'react'
import { Button } from 'react-bootstrap'
import { AuthenticationContext } from '../Context/AuthenticationContext'
import { UserContext } from '../Context/UserContext'

export default function Logout(): JSX.Element {
  const { setIsAuthenticated } = useContext(AuthenticationContext)
  const { setUser } = useContext(UserContext)

  const onClickHandler: FormEventHandler<HTMLButtonElement> = () => {
    Cookies.remove('token')
    setIsAuthenticated(false)
    setUser({
      email: '',
      name: '',
    })
  }

  return (
    <Button variant="warning" onClick={onClickHandler}>
      Logout
    </Button>
  )
}
