import { createContext } from 'react'
import { User } from '../components/Otp/api'

export type IUserContext = {
  user: Partial<User>
  setUser: (arg: User) => void
}

export const UserContext = createContext<IUserContext>({
  user: {},
  setUser: () => {},
})
