import { useState } from 'react'
import { User } from './components/Otp/api'
import { AuthenticationContext } from './Context/AuthenticationContext'
import { UserContext } from './Context/UserContext'
import MainPage from './components/MainPage'

const useAuthentication = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  return [isAuthenticated, setIsAuthenticated]
}

const useUser = (): [User, React.Dispatch<React.SetStateAction<User>>] => {
  const [user, setUser] = useState<User>({
    name: '',
    email: '',
  })

  return [user, setUser]
}

function App(): JSX.Element {
  const [isAuthenticated, setIsAuthenticated] = useAuthentication()
  const [user, setUser] = useUser()

  return (
    <AuthenticationContext.Provider
      value={{ isAuthenticated, setIsAuthenticated }}
    >
      <UserContext.Provider value={{ user, setUser }}>
        <MainPage />;
      </UserContext.Provider>
    </AuthenticationContext.Provider>
  )
}

export default App
