import { createContext } from 'react'

export type Authentication = {
  isAuthenticated: boolean
  setIsAuthenticated: (arg: boolean) => void
}
export const AuthenticationContext = createContext<Authentication>({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
})
