import axios, { AxiosResponse } from 'axios'
import Cookie from 'js-cookie'
export type JWT = string

const apiUrl = process.env.REACT_APP_API_URL

const api = {
  user: `${apiUrl}/user`,
} as const

export type User = {
  email: string
  name: string
}

export type ApiErrorResponse = {
  message: string
}

export class ConfigError extends Error {}

const getConfig = () => {
  const token = Cookie.get('token')

  if (!token) {
    return Promise.reject(new ConfigError('Auto login failed. Login manually'))
  }

  return Promise.resolve({
    headers: { Authorization: `Bearer ${Cookie.get('token')}` },
  })
}

export const getUser = async (): Promise<AxiosResponse<User>> =>
  getConfig().then((config) => axios.get(api.user, config))

export const updateUser = async (
  user: Partial<User>,
): Promise<AxiosResponse<User>> =>
  getConfig().then((config) => axios.put(api.user, user, config))
