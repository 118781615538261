export const isOfType = <T>(input: unknown, keys: Array<keyof T>): input is T =>
  keys.every((key) => (input as T)[key] !== undefined)

const assertTypeErrorMsg = "Input isn't in correct type"
export function assertTypeOrError<T>(
  input: unknown,
  keys: (keyof T)[] = [],
  errerMsg = assertTypeErrorMsg,
): asserts input is T {
  if (!isOfType<T>(input, keys)) {
    console.log(errerMsg, { input })
    throw new Error(errerMsg)
  }
}
